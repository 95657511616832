<template>
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="body">
        <div class="main-content-header">
          <div class="item">
            <div class="title">
              <span class="title-2">
                <a-icon type="info-circle" />
                Tab Bar的图标（icon）大小限制为 40kb，建议尺寸为 81px * 81px
              </span>
            </div>
          </div>
          <div class="item">
            <a-button type="primary" @click="handleSave">
              提交保存
            </a-button>
          </div>
        </div>

        <div class="icons">
          <div class="item" v-for="(item, index) in icons" :key="item.id">
            <div class="title">{{ item.name }}</div>
            <div class="content">
              <div class="icon">
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :data="{
                    token: qiniuData.token
                  }"
                  :before-upload="beforeUpload"
                  @change="handleChange($event, index, 0)"
                >
                  <img
                    class="img"
                    v-if="item.dicon"
                    :src="item.dicon"
                    alt="icon"
                  />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
                <span class="text">未选中</span>
              </div>
              <div class="icon icon-2">
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :data="{
                    token: qiniuData.token
                  }"
                  :before-upload="beforeUpload"
                  @change="handleChange($event, index, 1)"
                >
                  <img
                    class="img"
                    v-if="item.icon"
                    :src="item.icon"
                    alt="icon"
                  />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
                <span class="text">选中</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: '',
      subTitle: '用户端',
      icons: [],
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      loading: false
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  created() {
    this.title = this.$getPageTitle()
    this.getClientUserTabBarIcon()
  },
  methods: {
    getClientUserTabBarIcon() {
      // 获取用户端tabBar图标
      this.$axios.getClientUserTabBarIcon().then((res) => {
        const lists = res.data.data.list
        const icons = []
        lists.forEach((e) => {
          icons.push({
            id: e.id,
            icon: e.icon,
            dicon: e.dicon,
            name: e.name
          })
        })
        this.icons = icons
      })
    },
    // async beforeUpload(file) {
    //   // 上传检验
    //   const examine = {
    //     width: 81,
    //     height: 81
    //   }
    //   await this.$examineImgWH(file, examine)
    // },
    beforeUpload() {
      return true
    },
    handleChange(info, index, type) {
      // 上传
      const item = this.icons[index]
      if (info.file.status === 'uploading') {
        if (item.icon) {
          item.icon = ''
        }
        if (item.dicon) {
          item.dicon = ''
        }
        this.icons.splice(index, 1, item)
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        const url = this.qiniuData.host + info.file.response.key
        if (type) {
          // 选中
          item.icon = url
        } else {
          // 未选中
          item.dicon = url
        }
        this.icons.splice(index, 1, item)
        this.loading = false
      }
    },
    handleSave() {
      // 保存
      const data = {
        icon: this.icons
      }
      this.$axios.saveClientUserTabBarIcon(data).then(() => {
        this.$message.success('操作成功')
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 24px 32px;
}

.main-content-header {
  padding: 0 0 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.title-2 {
  margin-left: 0;
}

.title-2 .anticon {
  margin-right: 9px;
}

.icons {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.icons >>> .ant-upload.ant-upload-select-picture-card {
  margin: 0;
}

.icons .item {
  width: 312px;
  text-align: center;
}

.icons .item:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.icons .title {
  font-weight: bold;
  margin-bottom: 16px;
}

.icons .content {
  display: flex;
  justify-content: center;
}

.icons .uploader,
.icons >>> .ant-upload.ant-upload-select-picture-card,
.icons >>> .ant-upload.ant-upload-select-picture-card > .ant-upload {
  width: 80px;
  height: 80px;
}

.icons .icon-2 {
  margin-left: 12px;
}

.icons .text {
  display: block;
  color: #999999;
}
</style>
